<template>
    <div class="modal add_step secondary" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_body">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <div class="upload_image image_create m-0">
                    <image-library v-model="form.dashboard_thumb" image-type="dashboard-thumb" :is-inside="true" />
                </div>
                <div class="step_content">
                    <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="new-form-journey">
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Journey Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                        <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: 30 Day's Challenge" rules="required" label="Journey Name" />
                                    </div>
                                    <ErrorMessage name="name" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div class="action_wpr">
                        <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Creating' : 'Create' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'New Journey',

        data () {
            return{
                form: {
                    name: '',
                    dashboard_thumb: ''
                }
            }
        },

        props: {
            modelValue: Boolean,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.form = {
                        name: '',
                        dashboard_thumb: ''
                    };
                }
            }
        },

        computed: mapState({
            loader: state => state.journeyModule.journeyCreateLoader,
        }),

        methods: {
            ...mapActions({
                createJourney: 'journeyModule/createJourney',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm = this;

                const journeyForm = vm.$refs['new-form-journey'];

                journeyForm.validate().then((result) => {
                    if (result.valid) {
                        vm.handleCreateJourney(journeyForm);
                    }
                });
            },

            handleCreateJourney (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.createJourney(vm.form).then((result) => {
                    if (result) {
                        if (vm.$parent.$parent && vm.$parent.$parent.refreshJourney) {
                            vm.$parent.$parent.refreshJourney();
                        } else {
                            vm.$parent.refreshJourney();
                        }

                        vm.closeModal();
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .modal.add_step>.modal_container {
        max-width: 600px;
    }

    .step_content {
        padding: 20px 30px 20px 30px;
        flex: 1;
    }

    .step_content .action_wpr {
        width: 100%;
        padding-top: 5px;
    }

    .close_btn {
        position: absolute;
        right: 15px;
        left: auto;
        top: 15px;
        border: 0;
        background: rgba(50, 55, 59, .6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width: 20px;
    }

    .switch_option h5 {
        text-align: left;
        margin: 0;
    }

    .upload_image label {
        height: 100%;
        border-radius: 12px 0 0 12px;
    }

    .upload_image.image_create .upload_wpr {
        height: 100%;
    }
</style>
